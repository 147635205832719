<template>
  <div>
    <v-data-table
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
      :server-items-length="maxRecords"
      :options.sync="options"
      :headers="headers"
      :items="franchisesData"
      :search="search"
    >
      <template v-slot:[`item.fantasy_name`]="{ item }">
        <v-flex>
          <v-layout>
            <v-row justify="center">
              <v-col cols="auto">
                <span>{{ item.fantasy_name }}</span>
              </v-col>
            </v-row>
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.cnpj`]="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ item.cnpj | cnpj }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.contacts`]="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            <a @click="toggleContactsDialog(item)"> {{ getLabelContacts(item.contacts) }} </a>
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.total_franchisees`]="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            {{ item.total_franchisees ? item.total_franchisees : 0 }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-flex>
          <v-layout justify-center align-center>
            <v-icon class="mr-1" small :color="getStatusLabel(isActiveLabel(item.active)).color"> fiber_manual_record </v-icon>
            {{ getStatusLabel(isActiveLabel(item.active)).label }}
          </v-layout>
        </v-flex>
      </template>
      <template v-slot:[`item.options`]="{ item }">
        <v-menu :close-on-content-click="false">
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="goToEditFranchise(item)">
              <v-list-item-title>Ver dados da rede </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.active" @click="openDialog(item)">
              <v-list-item-title>Cadastrar Churn</v-list-item-title>
            </v-list-item>
            <v-list-item v-else @click="unsetFranchiseChurn(item)">
              <v-list-item-title>Remover Churn</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openDialogHistory(item)">
              <v-list-item-title>Histórico de Churn</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <dialog-history-churn v-model="historyChurn" type="franchises" :retailer-name="name" :list-churn="listChurn" :retailer-cnpj="cnpj" :client-id="id">
    </dialog-history-churn>
    <dialog-churn v-model="dialogSetChurn" :client_id="id" :retailer-name="name" :retailer-cnpj="cnpj" :value="dialogSetChurn" @click="setChurn">
    </dialog-churn>
    <contacts-details-dialog v-model="isDialogOpen" :item-selected="itemSelected" />
  </div>
</template>

<script>
import { QUERY_GET_FRANCHISES_PAGINATED, MUTATION_SET_CHURN, MUTATION_UNSET_CHURN } from '@/modules/franchises/graphql'
import { getStatusLabel } from '@/helpers/statusLabel'
export default {
  name: 'FranchisesIndexTable',
  components: {
    ContactsDetailsDialog: () => import('@/components/base/ContactsDetailsDialog.vue'),
    DialogChurn: () => import('@/components/atomic-components/molecules/DialogChurn.vue'),
    DialogHistoryChurn: () => import('@/components/atomic-components/molecules/DialogHistoryChurn.vue')
  },
  props: {
    search: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    franchisesData: [],
    itemSelected: {},
    options: {
      page: 1,
      pageSize: 10,
      search: null,
      sort: [],
      sortDirection: []
    },
    name: '',
    cnpj: '',
    id: '',
    dialogSetChurn: false,
    maxRecords: 0,
    isDialogOpen: false,
    historyChurn: false,
    loadingHistory: false,
    listChurn: []
  }),
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: '_id',
          align: 'center',
          sortable: false,
          width: '10%'
        },
        {
          text: 'Nome',
          value: 'fantasy_name',
          align: 'center',
          width: '15%'
        },
        {
          text: 'CNPJ',
          value: 'cnpj',
          align: 'center',
          width: '24%',
          sortable: false
        },
        {
          text: 'Contatos',
          value: 'contacts',
          align: 'center',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Quantidade de franqueados',
          value: 'total_franchisees',
          align: 'center',
          sortable: false,
          width: '18%'
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'center'
        },
        {
          text: '',
          value: 'options',
          align: 'center',
          width: '10%'
        }
      ]
    },
    loading() {
      return this.$apollo.loading
    },
    context() {
      return {
        headers: {
          authorization: localStorage.getItem('session_id')
        },
        uri: this.$microservicesUrls['crm']
      }
    }
  },
  apollo: {
    franchises: {
      query: QUERY_GET_FRANCHISES_PAGINATED,
      fetchPolicy: 'network-only',
      variables() {
        return {
          pagination: {
            page: this.options.page || 1,
            pageSize: this.options.itemsPerPage || 10,
            search: this.search || null,
            sort: this.options.sortBy || [],
            sortDirection: (this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))) || []
          }
        }
      },
      update({ franchises: { count, data } }) {
        this.maxRecords = count
        this.franchisesData = data.map(item => {
          return { ...item, active: item.active === null ? true : item.active }
        })
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  watch: {
    search(val) {
      this.options.page = 1
    }
  },
  methods: {
    getStatusLabel,
    async unsetFranchiseChurn(item) {
      const confirmation = await this.$confirm({
        title: 'Remover churn',
        message: 'Deseja remover o churn?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UNSET_CHURN,
          variables: {
            franchise_id: item._id
          },
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })
        setTimeout(() => {
          this.$alert({
            alert_message: `Churn Removido com sucesso`,
            alert_title: 'Sucesso!',
            alert_color: 'success',
            alert_icon: 'mdi-check-circle'
          })
          this.$router.go(this.$router.currentRoute)
        }, 300)
      } catch {
        this.$alert({
          alert_message: `Ops, parece que houve algum erro`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    isActiveLabel(active) {
      return active ? 'active' : 'inactive'
    },
    openDialogHistory(item) {
      this.id = item._id
      this.name = item.fantasy_name
      this.cnpj = item.cnpj
      this.historyChurn = true
      this.listChurn = item.history || []
    },
    openDialog(item) {
      this.id = item._id
      this.name = item.fantasy_name
      this.cnpj = item.cnpj
      this.dialogSetChurn = true
    },
    async setChurn(dataChurn) {
      const confirmation = await this.$confirm({
        title: 'Cadastrar churn',
        message: 'Deseja cadastrar o churn?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      try {
        let phase = this.translatePhase(dataChurn.phase)
        let params = {
          cause: dataChurn.cause,
          cause_details: dataChurn.cause_details || dataChurn.details,
          details: dataChurn.details,
          phase: phase,
          reason: dataChurn.reason,
          inactivated_at: dataChurn.inactivated_at,
          billed_client: dataChurn.billed_client
        }
        await this.$apollo.mutate({
          mutation: MUTATION_SET_CHURN,
          variables: {
            franchise_id: dataChurn.client_id,
            churn_options: params
          },
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })
        setTimeout(() => {
          this.dialogSetChurn = false
          this.$alert({
            alert_message: `Churn cadastrado com sucesso`,
            alert_title: 'Sucesso!',
            alert_color: 'success',
            alert_icon: 'mdi-check-circle'
          })
          this.$apollo.queries.franchises.refetch()
        }, 300)
      } catch (error) {
        console.log(error)
        this.$alert({
          alert_message: `Ops, parece que houve algum erro`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    translatePhase(phase) {
      switch (phase) {
        case 'Comercial':
          return 'comercial'
        case 'CX':
          return 'cx'
        case 'CS':
          return 'cs'
        case 'Parcerias':
          return 'partnerships'
        case 'Financeiro':
          return 'financial'
      }
    },
    getLabelContacts(contacts) {
      return contacts.length ? `${contacts.length} contato${contacts.length === 1 ? '' : 's'}` : 'Nenhum contato'
    },
    goToEditFranchise(franchise) {
      this.$router.push(`/franchises/${franchise._id}/edit/dados-da-rede`)
    },
    toggleContactsDialog(itemSelected) {
      this.itemSelected = itemSelected
      this.isDialogOpen = true
    }
  }
}
</script>
